// Generated by Framer (ddd30d5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, ResolveLinks, RichText, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import NavBarDropDownLinks from "./ooQ_F8D0b";
const NavBarDropDownLinksFonts = getFonts(NavBarDropDownLinks);

const cycleOrder = ["YHFeVBbd5", "lQQqa2vrG"];

const serializationHash = "framer-O9EDV"

const variantClassNames = {lQQqa2vrG: "framer-v-10k5ymk", YHFeVBbd5: "framer-v-dyo95x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "YHFeVBbd5", Phone: "lQQqa2vrG"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YHFeVBbd5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YHFeVBbd5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "lQQqa2vrG") return true
return false
}

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dyo95x", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"YHFeVBbd5"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)", ...style}} variants={{lQQqa2vrG: {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: "none"}}} {...addPropertyOverrides({lQQqa2vrG: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBSZWd1bGFy", "--framer-font-family": "\"Brandon Text Regular\", \"Brandon Text Regular Placeholder\", sans-serif", "--framer-font-size": "10px", "--framer-letter-spacing": "0.05em", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgba(0, 0, 0, 0.4))"}}>Humans in the loop</motion.p></React.Fragment>} className={"framer-1j0hi5x"} fonts={["CUSTOM;Brandon Text Regular"]} layoutDependency={layoutDependency} layoutId={"iKqJpcxGP"} style={{"--extracted-r6o4lv": "rgba(0, 0, 0, 0.4)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/>)}<ResolveLinks links={[{href: {webPageId: "R08rGjILx"}, implicitPathVariables: undefined}, {href: {webPageId: "R08rGjILx"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider height={32} y={((componentViewport?.y || 0) + 16 + 0)} {...addPropertyOverrides({lQQqa2vrG: {y: ((componentViewport?.y || 0) + 8 + 23)}}, baseVariant, gestureVariant)}><motion.div className={"framer-1hoerte-container"} layoutDependency={layoutDependency} layoutId={"qjrTg26oL-container"}><NavBarDropDownLinks height={"100%"} id={"qjrTg26oL"} IZqhAGvUs={false} K5i4c2a_v={"UsersThree"} layoutId={"qjrTg26oL"} oPSwwJqgK={"Meet Our Team"} tKvlxBpmS={resolvedLinks[0]} uPRFjzpqV width={"100%"} {...addPropertyOverrides({lQQqa2vrG: {tKvlxBpmS: resolvedLinks[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "wSl6bZOAS"}, implicitPathVariables: undefined}, {href: {webPageId: "wSl6bZOAS"}, implicitPathVariables: undefined}]}>{(resolvedLinks1) => (
<ComponentViewportProvider height={32} y={((componentViewport?.y || 0) + 16 + 40)} {...addPropertyOverrides({lQQqa2vrG: {y: ((componentViewport?.y || 0) + 8 + 63)}}, baseVariant, gestureVariant)}><motion.div className={"framer-17w4wt5-container"} layoutDependency={layoutDependency} layoutId={"Cu8IGcG3q-container"}><NavBarDropDownLinks height={"100%"} id={"Cu8IGcG3q"} IZqhAGvUs={false} K5i4c2a_v={"Users"} layoutId={"Cu8IGcG3q"} oPSwwJqgK={"Meet Our Analysts"} tKvlxBpmS={resolvedLinks1[0]} uPRFjzpqV width={"100%"} {...addPropertyOverrides({lQQqa2vrG: {tKvlxBpmS: resolvedLinks1[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-O9EDV.framer-jk2z4, .framer-O9EDV .framer-jk2z4 { display: block; }", ".framer-O9EDV.framer-dyo95x { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; padding: 16px; position: relative; width: 160px; }", ".framer-O9EDV .framer-1j0hi5x { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-O9EDV .framer-1hoerte-container, .framer-O9EDV .framer-17w4wt5-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-O9EDV.framer-dyo95x { gap: 0px; } .framer-O9EDV.framer-dyo95x > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-O9EDV.framer-dyo95x > :first-child { margin-top: 0px; } .framer-O9EDV.framer-dyo95x > :last-child { margin-bottom: 0px; } }", ".framer-O9EDV.framer-v-10k5ymk.framer-dyo95x { align-content: center; align-items: center; padding: 8px 0px 0px 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 104
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"lQQqa2vrG":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCKoOXQWxC: React.ComponentType<Props> = withCSS(Component, css, "framer-O9EDV") as typeof Component;
export default FramerCKoOXQWxC;

FramerCKoOXQWxC.displayName = "Links: Resources";

FramerCKoOXQWxC.defaultProps = {height: 104, width: 160};

addPropertyControls(FramerCKoOXQWxC, {variant: {options: ["YHFeVBbd5", "lQQqa2vrG"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerCKoOXQWxC, [{explicitInter: true, fonts: [{family: "Brandon Text Regular", source: "custom", url: "https://framerusercontent.com/assets/UiqYoOgOidN7cJ2idK1gh0xVw5I.otf"}]}, ...NavBarDropDownLinksFonts], {supportsExplicitInterCodegen: true})